import React from "react";
import { v4 as uuidv4 } from 'uuid';

function PersonalData() {
    
    return <div className="PersonalData">
        <h1>Persönliche Daten</h1>

        <div className="row profileImage">
            <div className="left">Foto hinzufügen:</div>
            <div className="right">
                <img src={"https://avatars.dicebear.com/api/personas/" + uuidv4() + ".svg"} alt="Avatar" />
                <button>Bearbeiten</button>
            </div>
        </div>
        <div className="row">
            <div className="left">Anrede:</div>
            <div className="right">
                <select name="salutation" id="salutation">
                    <option value="notSpecified">Keine Angaben</option>
                    <option value="female">Frau</option>
                    <option value="male">Herr</option>
                    <option value="diverse">Divers</option>
                </select>
                <select name="title" id="title">
                    <option value="notSpecified">Keine Angaben</option>
                    <option value="Prof.">Prof.</option>
                    <option value="Dr.">Dr.</option>
                    <option value="Prof.Dr.">Prof.Dr.</option>
                </select>
            </div>
        </div>
        <div className="row">
            <div className="left">Vorname</div>
            <div className="right">
                <input type="text" name="firstname" placeholder="Vorname" />
            </div>
        </div>
        <div className="row">
            <div className="left">Nachname</div>
            <div className="right">
                <input type="text" name="lastname" placeholder="lastname" />
            </div>
        </div>
        <div className="row">
            <div className="left">Geburtstag:</div>
            <div className="right">
                <input type="date" name="dateofbirth" id="dateofbirth" />
            </div>
        </div>
        <div className="row">
            <div className="left">Bio. Geschlecht</div>
            <div className="right">
                <select name="biologicalGender" id="biologicalGender">
                    <option value="female">Weiblich</option>
                    <option value="male">Männlich</option>
                </select>
            </div>
        </div>
        <div className="row">
            <div className="left">Geschlechtsidentität</div>
            <div className="right">
                <select name="genderIdentity" id="genderIdentity">
                    <option value="diverse">Divers</option>
                    <option value="female">Weiblich</option>
                    <option value="male">Männlich</option>
                </select>
            </div>
        </div>
        <div className="row">
            <div className="left">Versicherte…</div>
            <div className="right">
                <input type="text" name="healthInsuranceNumber" placeholder="Versichertennummer" />
            </div>
        </div>
        <div className="row">
            <div className="left">Krankenkasse:</div>
            <div className="right">
                <input type="text" name="healthInsurance" />
            </div>
        </div>
        <div className="row">
            <div className="left">Straße:</div>
            <div className="right">
                <input type="text" name="street" />
            </div>
        </div>
        <div className="row">
            <div className="left">Hausnummer:</div>
            <div className="right">
                <input type="text" name="houseNumber" />
            </div>
        </div>
        <div className="row">
            <div className="left">Postleitzahl:</div>
            <div className="right">
                <input type="text" name="zip" />
            </div>
        </div>
        <div className="row">
            <div className="left">Stadt:</div>
            <div className="right">
                <input type="text" name="city" />
            </div>
        </div>
        <div className="row">
            <div className="left">E-Mail:</div>
            <div className="right">
                <input type="email" name="email" />
            </div>
        </div>
        <div className="row">
            <div className="left">Handy:</div>
            <div className="right">
                <input type="text" name="phone" />
            </div>
        </div>
    </div>;
}

export default PersonalData;