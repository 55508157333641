import React, { useState } from "react";

import PersonalData from "./PersonalData";
import Account from "./Account";
import Privacy from "./Privacy";
import AboutNibyou from "./AboutNibyou";
import { Link } from "react-router-dom";

function ProfilePage() {
    const [viewMode, setViewMode] = useState('personalData');

    return <div className="Content ProfilePage">
        <div className="menuWrapper">
            <div className="header">
                <h2>Einstellungen</h2>
                <Link to="/">
                    Fertig
                </Link>
            </div>
            <div className="body">
                <button className={ viewMode === 'personalData' ? 'active' : ''} onClick={() => {setViewMode('personalData')}}>Persönliche Daten</button>
                <button className={ viewMode === 'account' ? 'active' : ''} onClick={() => {setViewMode('account')}}>Account</button>
                <button className={ viewMode === 'privacy' ? 'active' : ''} onClick={() => {setViewMode('privacy')}}>Privatsphäre</button>
                <button className={ viewMode === 'aboutNibyou' ? 'active' : ''} onClick={() => {setViewMode('aboutNibyou')}}>Über Nibyou</button>
            </div>
        </div>
        <div className="contentWrapper">
        { viewMode === 'personalData' && <PersonalData /> }
        { viewMode === 'account' && <Account /> }
        { viewMode === 'privacy' && <Privacy /> }
        { viewMode === 'aboutNibyou' && <AboutNibyou /> }
        </div>
    </div>;
}

export default ProfilePage;