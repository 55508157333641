import React from "react";
import { Link } from "react-router-dom";

function HeaderMobile() {

    return <header className="ny_header__mobile">
        <div className="ny_header__left">
            <Link to="/profile" className="ny_sideNaveItem">
                <img src="/icons/profile.svg" alt="Logo"/>
            </Link>
        </div>
        <div className="ny_header__right">
            <Link to="/logout" className="ny_sideNaveItem">
                <img src="/icons/logout.svg" alt="Logo"/>
            </Link>
        </div>
    </header>;
}

export default HeaderMobile;