import React from "react";

function AboutNibyouMobile(props) {
    
    return <div className="AboutNibyouMobile">
        <div className="header">
            <h1>Über Nibyou</h1>
            <span onClick={() => {props.setViewMode('')}}>Fertig</span>
        </div>
        <h2>Aktuelle Rechtsunterlagen</h2>
        
        <p>Datenschutzerklärung von Frau/Herr Experte</p>
        <a href="www.link.de/stehthieragbtralalalalalaaajcdsds" target="_blank">www.link.de/stehthieragbtralalalalalaaajcdsds</a>
    </div>;
}

export default AboutNibyouMobile;