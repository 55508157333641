import React, { useState } from "react";
import { Link } from "react-router-dom";

function PasswordRecoveryForm(props) {
    return <form className="PasswordRecoveryForm">
        <h1>Passwort mit Wiederherstellungspasswort zurücksetzen</h1>

        <div id="recoveryPasswordWrapper">
            <label>Wiederherstellungspasswort</label>
            <input type="password" id="recoveryPassword" name="recoveryPassword" />
        </div>
        <div id="recoveryPasswordForgotWrapper">
            <p onClick={() => {props.setRecoveryPasswordForgotten(true)}}>Wiederherstellungspasswort vergessen?</p>
        </div>
        <div>
            <label>Neues Passwort eingeben</label>
            <input type="password" id="newPassword" name="newPassword" />
        </div>
        <div>
            <label>Neues Passwort wiederholen</label>
            <input type="password" id="newPasswordRepeat" name="newPasswordRepeat" />
        </div>
        <button className="changePassword" type="button">Passwort ändern</button>
        <div className="formFooter">
            <Link to="/login" className="cancel">Abbrechen</Link>
            <button type="button">Fertig</button>
        </div>
    </form>
}

function RecoveryMailSentMessage() {
    return <div className="RecoveryMailSentMessage">
        <h1>E-Mail wurde versendet</h1>
        <p>Es wurde an deine E-Mail ein Link versendet. <br /> <br /> Hiermit kannst du deinen Account löschen und
        eine Neuanlage durch deinen Experten anfordern. <br /> <br /> Bitte folge den Anweisungen in der E-Mail.</p>
    </div>
}

function RecoveryPasswordForgottenMessage(props) {
    return <div className="RecoveryPasswordForgotten">
        <p>
            Sie sollten mit Ihrer Anmeldung ein Wiederherstellungspasswort per PDF auf Ihre E-Mail erhalten haben. <br /> <br />
            Wenn Sie das Wiederherstellungspasswort wirklich verloren haben, müssen wir aus Sicherheitsgründen Ihren Account löschen. <br />  <br />
            Ihr Experte muss Ihnen dann einen neuen Account anlegen.
        </p>

        <button className="cancel"  onClick={() => {props.setRecoveryPasswordForgotten(false)}}>Abbrechen</button>
        <button  onClick={() => {props.setRecoveryMailRequired(true)}}>Ja, ich möchte mein Account löschen</button>
    </div>
}

function PasswordRecoveryPage(props) {
    const [recoveryMailRequired, setRecoveryMailRequired] = useState(false);
    const [recoveryPasswordForgotten, setRecoveryPasswordForgotten] = useState(false);

    return <div className="Content PasswordRecoveryPage">
        <header className="header">
            <Link to="/">
                <img src="/icons/nibyou_big.svg" alt="Logo" />
            </Link>
        </header>
        
        { !recoveryMailRequired && !recoveryPasswordForgotten && <PasswordRecoveryForm setRecoveryPasswordForgotten={setRecoveryPasswordForgotten} /> }

        { !recoveryMailRequired && recoveryPasswordForgotten && <RecoveryPasswordForgottenMessage setRecoveryMailRequired={setRecoveryMailRequired} setRecoveryPasswordForgotten={setRecoveryPasswordForgotten} /> }

        { recoveryMailRequired && recoveryPasswordForgotten && <RecoveryMailSentMessage /> }
        
        <footer>
            <p>Ein Service der Nibyou GmbH</p>
        </footer>
    </div>;
}

export default PasswordRecoveryPage;