import React, { useState } from "react";
import { login } from '@nibyou/auth-connector';
import { Link } from "react-router-dom";

function userIsAuthenticated() {
    if (localStorage.getItem('refreshToken')) {
        return true;
    }

    return false;
}

async function handleSubmit(props) {
    const email = props.email;
    const password = props.password;

    login(email, password).then(() => {
        if (!userIsAuthenticated()) {
            return;
        }
    
        if (props.setIsAuthenticated) {
            props.setIsAuthenticated(true);
        } else {
            window.location.href = "/";
        }
    });
}

function LoginPage(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    if (userIsAuthenticated()) {
        window.location.href = "/";
    }

    return <div className="Content LoginPage">
        <header className="header">
            <img src="/icons/nibyou_big.svg" alt="Logo" />
        </header>
        <h1>Login</h1>
        <form id="loginForm">
            <input type="email" placeholder="E-Mail" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
            <input type="password" placeholder="Passwort" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
            <button type="button" onClick={() => handleSubmit({
                email: email,
                password: password,
                setIsAuthenticated: props.setIsAuthenticated
            })}>Login
            </button>
        </form>
        <Link to="/passwordRecovery">Passwort vergessen?</Link>
        <footer>
            <p>Ein Service der Nibyou GmbH</p>
        </footer>
    </div>;
}

export default LoginPage;