import React from "react";

function Privacy() {
    
    return <div className="Privacy">
        <h1>Account</h1>
        
        <div>
            <span>Löschung alle Daten durchführen nach Artikel 17 DSGVO</span>
            <button>Alle Daten löschen</button>
        </div>
        <p>Hiermit nehmen Sie eine vollständige Löschung Ihrer Daten in Nibyou vor. Das können Sie nicht rückgängig machen.</p>
    </div>;
}

export default Privacy;