import React from "react";

function AboutNibyou() {
    
    return <div className="AboutNibyou">
        <h1>Über Nibyou</h1>
        
        <p>Aktuelle Rechtsunterlagen</p>
        <div>
            <span>Datenschutzerklärung von Frau/Herr Experte</span>
            <a href="www.link.de/stehthieragbtralalalalalaaajcdsds" target="_blank">www.link.de/stehthieragbtralalalalalaaajcdsds</a>
        </div>
    </div>;
}

export default AboutNibyou;