import React from "react";

import SearchBar from "./../modules/SearchBar";
import FooterMobile from "./FooterMobile";
import HeaderMobile from "./HeaderMobile";

function ChatMobile(props) {

    return <div className="ChatMobile">
        <HeaderMobile />
        <SearchBar noButton={true} />
        <p>///Chat</p>
        <FooterMobile view={props.view} setView={props.setView} />
    </div>;
}

export default ChatMobile;