import React from "react";

function FooterMobile(props) {

    return <footer className="FooterMobile">
        <div className="ny_header__left">
            <button onClick={() => {props.setView('chat')}}>
                { props.view === 'chat' && <img src="/icons/chat.svg" alt="Logo"/>}
                { props.view !== 'chat' && <img src="/icons/chat_grey.svg" alt="Logo"/>}
                <span>Chat</span>
            </button>
        </div>
        <div className="ny_header__right">
            <button onClick={() => {props.setView('documents')}}>
                { props.view === 'documents' && <img src="/icons/document.svg" alt="Logo"/>}
                { props.view !== 'documents' && <img src="/icons/document_grey.svg" alt="Logo"/>}
                <span>Documente</span>
            </button>
        </div>
    </footer>;
}

export default FooterMobile;