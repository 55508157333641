import React, { useState } from "react";

function Account() {
    const [viewMode, setViewMode] = useState('changePassword');
    
    if (viewMode === 'changePassword') {
        return <div className="Account">
            <h1>Account</h1>
            <p>Passwort ändern</p>

            <div className="row">
                <div className="left">
                    <span>Altes Passwort eingeben</span>
                </div>
                <div className="right">
                    <input type="password" name="oldPassword" />
                    <span className="pointer" onClick={() => {setViewMode('recoverPassword')}}>Passwort vergessen?</span>
                </div>
            </div>
            <div className="row">
                <div className="left">
                    <span>Neues Passwort eingeben</span>
                </div>
                <div className="right">
                    <input type="password" name="newPassword" />
                </div>
            </div>
            <div className="row">
                <div className="left">
                    <span>Neues Passwort wiederholen</span>
                </div>
                <div className="right">
                    <input type="password" name="newPasswordRepeat" />
                    <button>Ändern</button>
                </div>
            </div>
        </div>;
    } else {
        return <div className="Account">
            <h1>Account</h1>
            <p>Passwort ändern - Wiederherstellungspasswort</p>

            <div className="row">
                <div className="left">
                    <span>Wiederherstellungspasswort</span>
                </div>
                <div className="right">
                    <input type="password" name="oldPassword" />
                    <span className="pointer">Wiederherstellungspasswort vergessen?</span>
                </div>
            </div>
            <div className="row">
                <div className="left">
                    <span>Neues Passwort eingeben</span>
                </div>
                <div className="right">
                    <input type="password" name="newPassword" />
                </div>
            </div>
            <div className="row">
                <div className="left">
                    <span>Neues Passwort wiederholen</span>
                </div>
                <div className="right">
                    <input type="password" name="newPasswordRepeat" />
                    <button>Ändern</button>
                </div>
            </div>
        </div>;
    }
}

export default Account;