import React, { useState } from "react";
import { Link } from "react-router-dom";

import PersonalDataMobile from "./PersonalDataMobile";
import AccountMobile from "./AccountMobile";
import PrivacyMobile from "./PrivacyMobile";
import AboutNibyouMobile from "./AboutNibyouMobile";

function ProfilePageMobile() {
    const [viewMode, setViewMode] = useState('');

    if (viewMode === '') {
        return <div className="ProfilePageMobile Menu">
            <div className="header">
                <h1>Einstellungen</h1>
                <Link to="/">
                    Fertig
                </Link>
            </div>
            <div className="body">
                <button onClick={() => {setViewMode('personalData')}}>Persönliche Daten</button>
                <button onClick={() => {setViewMode('account')}}>Account</button>
                <button onClick={() => {setViewMode('privacy')}}>Privatsphäre</button>
                <button onClick={() => {setViewMode('aboutNibyou')}}>Über Nibyou</button>
            </div>
        </div>;
    }

    return <div className="ProfilePageMobile">
        { viewMode === 'personalData' && <PersonalDataMobile setViewMode={setViewMode} /> }
        { viewMode === 'account' && <AccountMobile setViewMode={setViewMode} /> }
        { viewMode === 'privacy' && <PrivacyMobile setViewMode={setViewMode} /> }
        { viewMode === 'aboutNibyou' && <AboutNibyouMobile setViewMode={setViewMode} /> }
    </div>;
}

export default ProfilePageMobile;