import React from "react";
import { Link } from "react-router-dom";

import SearchBar from "./modules/SearchBar";
import ResizeAbleTextArea from "./modules/ResizeAbleTextArea";

function Chat(props) {

    return <div className="Content Chat">
        <div className="contentWrapper">
            <div className="chatWrapper">
                <div className="chatHeader">
                    <h3>Nachrichten</h3>
                    <Link to="/profile">
                        <img className="infoIcon" src="/icons/info-circle-solid.svg" alt="Icon"/>
                    </Link>
                    <img className="cameraIcon" src="/icons/camera.svg" alt="Icon"/>
                </div>
                <div className="chatBody">
                </div>
                <div className="chatFooter">
                    <ResizeAbleTextArea name={"message"} placeholder={"Schreiben..."} />
                    <div className="chatFileUpload">
                        <img src="/icons/image-regular.svg" alt="Icon" />
                    </div>
                </div>
            </div>
            <div className="documentsWrapper">
                <div className="documentsHeader">
                    <h3>Dokumente</h3>
                    <div className="searchWrapper">
                        <SearchBar />
                        <img className="addIcon" src="/icons/plus.svg" alt="Icon" />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default Chat;