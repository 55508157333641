import React, { useState, useEffect } from "react";

import Chat from "./Chat";

function Home() {
    const [view, setView] = useState('chat');
    
    useEffect(() => {
        return () => {
            setView('chat');
        };
    }, []);

    if (view === 'chat') {
        return <Chat setView={setView} />;
    }
}

export default Home;