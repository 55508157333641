import React from "react";
import { Link } from "react-router-dom";

function Header() {

    return <header className="ny_header">
        <div className="ny_header__left">
            <Link to="/profile" className="ny_sideNaveItem">
                <img src="/icons/profile.svg" alt="Logo"/>
                <span>Sie</span>
            </Link>
        </div>
        <img src="/icons/nibyou.svg" alt="Logo"/>
        <div className="ny_header__right">
            <Link to="/logout" className="ny_sideNaveItem">
                <img src="/icons/logout.svg" alt="Logo"/>
                <span>Ausloggen</span>
            </Link>
        </div>
    </header>;
}

export default Header;