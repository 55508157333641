import React from "react";

import SearchBar from "./../modules/SearchBar";
import FooterMobile from "./FooterMobile";
import HeaderMobile from "./HeaderMobile";

function DocumentsMobile(props) {

    return <div className="DocumentsMobile">
        <HeaderMobile />
        <div className="documentSearchWrapper">
            <SearchBar noButton={true} />
            <img className="addButton" src="/icons/plus.svg" alt="Icon" />
        </div>
        <p>///Dokumente</p>
        <FooterMobile view={props.view} setView={props.setView} />
    </div>;
}

export default DocumentsMobile;