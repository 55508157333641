import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Header from './Header';


function Layout() {
    const location = useLocation();

    if (location.pathname === '/login') {
        return <div className='App'>
            <Outlet />
        </div>
    }

    return <div className='App'>
        <Header />
        <main>
            <Outlet />
        </main>
    </div>;
}

export default Layout;