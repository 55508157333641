import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import Layout from './Layout';
import Home from './Home';
import LoginPage from './LoginPage';
import ProtectedRoutes from './ProtectedRoutes';
import Logout from './Logout';
import PasswordRecoveryPage from './PasswordRecoveryPage';
import ProfilePage from './ProfilePage';

import HomeMobile from './mobile/HomeMobile';
import ProfilePageMobile from './mobile/ProfilePageMobile';
import AccountDeletionPage from './AccountDeletionPage';

function NotFoundPage() {
    return <div className='NotFoundPage'>
        <div>
            <h1>404 Seite nicht gefunden</h1>
            <p>
                Lass dich nicht entmutigen, wahrscheinlich haben wir einen Fehler gemacht. <br />
                Geh am besten zurück zur
            </p>
            <Link to="/">
                Homepage
            </Link>
        </div>
    </div>;
}

function App() {
    const [isMobile, setIsMobile] = useState(false);

    function handleWindowResize()
    {
        if (window.innerWidth < 576) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        handleWindowResize();
    }, []);

    window.addEventListener('resize', handleWindowResize);

    if (isMobile || (window.innerWidth < 576)) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="login" element={<LoginPage />} />
                    <Route path="passwordRecovery" element={<PasswordRecoveryPage />} />
                    <Route path="deletion" element={<AccountDeletionPage />} />
                    <Route path="logout" element={<Logout />} />
                    <Route path="*" element={<NotFoundPage />} />
    
                    <Route element={<ProtectedRoutes />}>
                        <Route index element={<HomeMobile />} />
                        <Route path="home" element={<HomeMobile />} />
                        <Route path="profile" element={<ProfilePageMobile />} />
                        <Route path="deletion" element={<AccountDeletionPage />} />
                        <Route path="login" element={<LoginPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<LoginPage />} />
                <Route path="passwordRecovery" element={<PasswordRecoveryPage />} />
                <Route path="deletion" element={<AccountDeletionPage />} />
                <Route path="logout" element={<Logout />} />
                <Route path="*" element={<NotFoundPage />} />

                <Route element={<ProtectedRoutes />}>
                    <Route element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="profile" element={<ProfilePage />} />
                        <Route path="deletion" element={<AccountDeletionPage />} />
                        <Route path="login" element={<LoginPage />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;