import React from "react";
import { Link } from "react-router-dom";

function AccountDeletionPage() {
    
    return <div className="Content AccountDeletionPage">
        <header className="header">
            <Link to="/">
                <img src="/icons/nibyou_big.svg" alt="Logo" />
            </Link>
        </header>
        
        <form>
            <h1>Löschung Ihres Account</h1>
            <p>
            Sehr geehrte Nutzer,<br /><br />

            Sollten Sie Ihr Wiederherstellungspasswort nicht mehr finden können, müssen wir Ihren Account löschen, 
            damit Ihr Experte einen neuen Account für Sie freischalten kann. Ein Zurücksetzen Ihres Passwortes ist 
            aus Sicherheitsgründen sowohl für Ihren Experten als auch für Nibyou selbst nicht möglich. <br />
            Ihr Experte erhält eine Benachrichtigung und kann nach der Löschung einen neuen Account für Sie anlegen. 
            Sollten Sie keine neuen Zugangsdaten erhalten, wenden Sie sich bitte an Ihren behandelnden Experten. 
            </p>

            <div>
                <label htmlFor="email">Meine E-Mail</label>
                <input type="text" id="email" name="email" />
            </div>

            <div className="consentWrapper">
                <label htmlFor="consent">Hiermit bestätige ich die Löschung meines Accounts.</label>
                <input type="checkbox" id="consent" name="consent" />
            </div>

            <div className="formFooter">
                <button>Account löschen</button>
            </div>
        </form>

        <footer>
            <p>Ein Service der Nibyou GmbH</p>
        </footer>
    </div>;
}

export default AccountDeletionPage;