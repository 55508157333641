import React, { useState } from "react";
import { Link } from "react-router-dom";

function AccountMobile(props) {
    const [viewMode, setViewMode] = useState('');
    
    if (viewMode === 'recoverPassword') {
        return <div className="Account">
            <div className="header">
                <h1>Account</h1>
                <span onClick={() => {props.setViewMode('')}}>Fertig</span>
            </div>
            <h2>Wiederherstellungspasswort</h2>

            <input type="password" name="oldPassword" placeholder="Wiederherstellungspasswort" />
            <p className="pointer" onClick={() => {setViewMode('accountDeletionMailSent')}}>Wiederherstellungspasswort vergessen?</p>
            <input type="password" name="newPassword" placeholder="Neues Passwort eingeben" />
            <input type="password" name="newPasswordRepeat" placeholder="Neues Passwort wiederholen" />
            
            <button>Ändern</button>
        </div>;
    }
    
    if (viewMode === 'accountDeletionMailSent') {
        return <div className="PasswordRecoveryPage">
            <header className="header">
                <Link to="/">
                    <img src="/icons/nibyou_big.svg" alt="Logo" />
                </Link>
            </header>
            
            <div className="RecoveryMailSentMessage">
                <h1>E-Mail wurde versendet</h1>
                <p>Es wurde an deine E-Mail ein Link versendet. <br /> <br /> Hiermit kannst du deinen Account löschen und
                eine Neuanlage durch deinen Experten anfordern. <br /> <br /> Bitte folge den Anweisungen in der E-Mail.</p>
            </div>
            
            <footer>
                <p>Ein Service der Nibyou GmbH</p>
            </footer>
        </div>;
    }

    return <div className="Account">
        <div className="header">
            <h1>Account</h1>
            <span onClick={() => {props.setViewMode('')}}>Fertig</span>
        </div>
        <h2>Passwort ändern</h2>

        <input type="password" name="oldPassword" placeholder="Altes Passwort eingeben"/>
        <p className="pointer" onClick={() => {setViewMode('recoverPassword')}}>Passwort vergessen?</p>
        <input type="password" name="newPassword" placeholder="Neues Passwort eingeben" />
        <input type="password" name="newPasswordRepeat" placeholder="Neues Passwort wiederholen" />

        <button>Passwort ändern</button>
    </div>;
}

export default AccountMobile;