import React from "react";

function PrivacyMobile(props) {
    
    return <div className="Privacy">

        <div className="header">
            <h1>Privatsphäre</h1>
            <span onClick={() => {props.setViewMode('')}}>Fertig</span>
        </div>
        <h2>Artikel 17 DSGVO</h2>
        
        <button>Alle Daten löschen</button>
        <p>Hiermit nehmen Sie eine vollständige Löschung Ihrer Daten in Nibyou vor. Das können Sie nicht rückgängig machen.</p>
    </div>;
}

export default PrivacyMobile;